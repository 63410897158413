/* Application environment
   ========================================================================== */
var template = {
    },
    forms = {
    };

// Define jQuery
$ = jQuery.noConflict();

// Globals
active = "is--active";
is_checked = 'is--checked';
is_hidden = 'is--hidden';
is_enabled = 'is--enabled';



;/* Template logic: Donation Gift for Rock and Leaf
 ========================================================================== */

template.donation_gift = function() {

    var $btns = $('.give-block__btn');

    var $options = $('.give-extra');

    $btns.click(function(){

        $('html, body').animate({
            scrollTop: $options.offset().top
        }, 1000);

    });


    $('input').change(function() {
        if (this.checked) {
            var response = $('label#' + $(this).attr('id')).html();
        }
    });

    var $give_colours = $('.give-colour');

    $give_colours.click(function(){

        var selector = $(this).attr('for');

        var $input = $('#' + selector);

        var max = parseInt($input.attr('max'));
        var min = parseInt($input.attr('min'));

        var value = $input.val() || 0;

        value++;

        $input.val( value );
        $input.trigger('change');

    });

    var $inputs = $("[name^='colour-']");

    $inputs.change(function(){

        var max = parseInt($(this).attr('max'));
        var min = parseInt($(this).attr('min'));
        if ($(this).val() > max)
        {
            $(this).val(max);
        }
        else if ($(this).val() < min)
        {
            $(this).val(min);
        }

    });

};;/* Template logic: Donation Widget
   ========================================================================== */

template.donation_widget_url = function(donate_urls, width) {
  state = (width > 1024) ? 'desktop' : 'mobile';

  $('#single-donation-box form.block, #single-donation-box .block form').attr('action', donate_urls.single[state]);
  $('#single-donation-box .block button').attr('href', donate_urls.single[state]);

  $('#monthly-donation-box form.block, #monthly-donation-box .block form').attr('action', donate_urls.recurring[state]);
  $('#monthly-donation-box .block button').attr('href', donate_urls.recurring[state]);
};

template.donation_inset_url = function(donate_urls_inset, width, donation_type) {
  state = (width > 1024) ? 'desktop' : 'mobile';

  if (donation_type === 'single') {
    $('.donation-widget form').attr('action', donate_urls_inset.single[state]);
  } else {
    $('.donation-widget form').attr('action', donate_urls_inset.recurring[state]);
  }
};

template.donation_widget = function() {

  var $donate = $('.donate, .custom-donation'),
    $donate_button = $('.btn--amount'),
    $donate_amount = $('.figure'),
    $donation_field = $('input.other-amount'),
    $donate_url_button = $('.rddonate-checkout-simple-link'),
    $donation_type_selector = $('.donation-type'),
    $donation_wrapper = $('.page-section--donation-boxes'),
    $donation_inset = $('.donation-widget'),
    donate_urls_widget = {
      single: {
        desktop: $donation_wrapper.attr('data-single'),
        mobile: $donation_wrapper.attr('data-single-mobile')
      },
      recurring: {
        desktop: $donation_wrapper.attr('data-recurring'),
        mobile: $donation_wrapper.attr('data-recurring-mobile')
      }
    },
    donate_urls_inset = {
      single: {
        desktop: $donation_inset.attr('data-single'),
        mobile: $donation_inset.attr('data-single-mobile')
      },
      recurring: {
        desktop: $donation_inset.attr('data-recurring'),
        mobile: $donation_inset.attr('data-recurring-mobile')
      }
    }
  ;

  $donate_button.on('click', function (e) {
    e.preventDefault();

    var value = $(this).find($donate_amount).text(),
      destination = $donation_field.next()
    ;

    $donate_button.removeClass(active);
    $(this).addClass(active);

    $(this).closest('form').find($donation_field).val(value);

    $(this).closest($donate).find($donation_field).trigger('change');
  });

  $donation_field.change(function() {
    var value = $(this).val(),
      url = $(this).closest($donate).attr('data-url').replace('[]', value);

    $(this).closest($donate).find($donate_url_button).attr('href', url);
  });

  $donation_field.on('keyup', function(e) {
    var value = $(this).val(),
      url = $(this).closest($donate).attr('data-url').replace('[]', value),
      valid = /^\d{0,7}(\.\d{0,2})?$/.test(value);

    if (!valid) {
      this.value = value.substring(0, value.length - 1);
    } else {
      $(this).closest($donate).find($donate_url_button).attr('href', url);
    }
  });

  $donation_type_selector.change(function(){
    var donation_type =  $(this).val();
    var $boxes = $('.donation_boxes');

    $boxes.hide();

    $('#' + donation_type + '-donation-box').show();

    $('.notice--donate').removeClass(active);

    $('.notice--'+ donation_type +'-donation').addClass(active);
  });

  $(document).ready(function() {
    $donation_type_selector.first().click();
  });

  if ($('.page-section--donation-boxes').length) {
    width = $(window).width();

    template.donation_widget_url(donate_urls_widget, width);

    $(window).resize(function() {
      width = $(window).width();

      template.donation_widget_url(donate_urls_widget, width);
    });
  }

  if ($('.donation-widget').length) {
    width = $(window).width();
    type = $('.donation-widget .donation-type:checked').val();

    template.donation_inset_url(donate_urls_inset, width, type);

    $(window).resize(function() {
      width = $(window).width();

      template.donation_inset_url(donate_urls_inset, width, type);
    });
  }

  $donation_inset.on('change', 'input[type="radio"]', function() {
    var type = $('[name="donation-type"]:checked').val(),
      width = $(window).width();

    template.donation_inset_url(donate_urls_inset, width, type);
  });

};
;/* Template logic: Lazy load
   ========================================================================== */

template.lazyload = function() {
	if ($().lazyload) {
	    $("img.lazy").lazyload({
	      effect : "fadeIn"
	    });
	}
};
;/* Template logic: Lightbox
   ========================================================================== */

template.lightbox = function() {

	var btn_video = $('a.btn--video'),
      video = $(btn_video).data( "video" );


    if ($().magnificPopup) {
        $(btn_video).magnificPopup({
            items: {
              src: video,
              type: 'iframe' 
            }
        });
    }    
};
;/* Template logic: Navigation
   ========================================================================== */

template.navigation = function() {

    var menu_toggle = $('a.menu-toggle'),
        navigation_main = $('nav.nav--main'),
        search_block = $('.search--header');

    menu_toggle.on('click', function(e) {
        e.preventDefault();
        $(this).toggleClass(active);
        navigation_main.toggleClass(active);
        search_block.hide();
    });

};
;/* Template logic: Search Slide
   ========================================================================== */

template.search_slide = function() {

  var search_block = $('.search--header'),
      search_toggle = $('.btn--search'),
      search_close = $('.btn--close'),
      btn_mobile = $('.btn--mobile'),
      navigation_main = $('nav.nav--main');

  search_toggle.click(function (e) {
    e.preventDefault();
    search_block.slideToggle();
    btn_mobile.removeClass(active);
    navigation_main.removeClass(active);
  });

  search_close.click(function (e) {
    e.preventDefault();
    search_block.slideUp();
  });

};
;/* Template logic: Custom Checkbox
   ========================================================================== */

forms.checkbox = function() {

	var checkbox = $('.checkbox'),
	    checkbox_overlay = $('.checkbox-overlay'),
	    label_checkbox = $('.label--checkbox')
	;

    label_checkbox.on('click', function() {

        $(this).next(checkbox_overlay).toggleClass(is_checked);

        if ( $(this).siblings(checkbox_overlay).hasClass(is_checked) ) {

          $(this).siblings(checkbox_overlay).children(checkbox).attr('checked', true);

        }  else {

          $(this).siblings(checkbox_overlay).children(checkbox).attr('checked', false);

        }

    });

    checkbox_overlay.on('click', function() {

        $(this).toggleClass(is_checked);

        if ( $(this).hasClass(is_checked) ) {

            $(this).children(checkbox).attr('checked', true);

        }  else {

            $(this).children(checkbox).attr('checked', false);

        }

    });

};
;/* Template logic: Custom Select
   ========================================================================== */
forms.select = function() {

    $('.custom-select').each(function () {

        var select = $(this);

        // create custom select
        select.wrap('<div class="[ select ]"></div>');
        select.after('<div class="[ option--selected ]"></div>');

        // Selected option
        var selected = select.next('div.option--selected ');

        // Populate selected option with first option text
        selected.text(select.children('option').eq(0).text());


        var custom_options = $('<div />', {
            'class': 'select__options'
        }).insertAfter(selected);


        for (var i = 0, len = $(this).children('option').length; i < len; i++) {
            $('<span />', {
                'class': 'option',
                text: select.children('option').eq(i).text(),
                rel: select.children('option').eq(i).val()
            }).appendTo(custom_options);
        }

        // Options
        var options = custom_options.children('span');

        select.closest('.select').on('click', function() {

            $(this).toggleClass(active);
            $(this).children(custom_options).toggleClass(active);
        });

        // Set initial Select value to first option rel
        var initialvalue = options.eq(0).attr('rel');
        select.val(initialvalue);

        // When option is clicked
        // Populate Selected option with text from option
        // &
        // Set Select value to option rel

        $('.detail').eq(0).addClass(active);

        options.on('click', function() {

            selected.text($(this).text());

            var value = ($(this).attr('rel'));

            select.val(value);

            $(this).trigger('change');

            // Contact details
            var option_index = $(this).index();
            var option_details = $(this).closest('.select').next('.option-details');
            option_details.children('.detail').removeClass(active);
            option_details.children('.detail').eq(option_index).addClass(active);

        });

    });

    $('.select--filter').on('click', function() {
        $(this).children('.option--selected-filter').toggleClass(active);
        $(this).children('.select__options').toggleClass(active);
    });
};
;/* Template logic: Custom Tooltip
   ========================================================================== */

forms.tooltip = function() {

	var $tooltip = $('.icon--tooltip'),
        $tip_info = $('.tooltip__information')
    ;

	$tooltip.on({
		mouseenter: function() {
			$(this).siblings($tip_info).addClass(active);
		},
		mouseleave: function() {
			$(this).siblings($tip_info).removeClass(active);
		}
	});

};
;$(function() {

    // Template logic
    template.lazyload();
    template.navigation();
    template.search_slide();
    template.donation_widget();
    template.lightbox();
    template.donation_gift();

    // Forms logic
    forms.checkbox();
    forms.select();
    forms.tooltip();

});

$(window).load(function() {

  if ($().masonry) {
      $('.grid--flexible').masonry({
          itemSelector: '.gi',
          percentPosition: true
      });
  }
  
});
